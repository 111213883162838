
import { defineComponent, computed } from "vue";
import ArtistsList from "../shared/ArtistsList.vue";
import EntityListTopContent from "../../entity/EntityListTopContent.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import { ArtistDto, useApiResource, useDocumentTitle } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Artists",
    components: { ArtistsList, EntityListTopContent, SpinnerContainer },
    setup() {
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.artists"));

        const { entities: entitiesContent, loading: loadingPageContent } = useApiResource("/api/artists_pages", {
            page: "1",
            pagination: "false",
            "order[sortNumber]": "asc",
        });

        const { entities, loading } = useApiResource("/api/artists", {
            page: "1",
            pagination: "false",
            "order[fullName]": "asc",
        });

        const creatorsNames = computed(() => {
            if (!entities.value) return [];

            return (entities.value as ArtistDto[]).map((entity) => entity.fullName) ?? [];
        });

        return { creatorsNames, entitiesContent, loading, loadingPageContent };
    },
});
