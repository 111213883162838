
import { computed, defineComponent, PropType } from "vue";
import Block from "../../../shared/components/Block.vue";

export default defineComponent({
    name: "ArtistsList",
    components: { Block },
    props: {
        artists: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    setup(props) {
        const alphabet = computed(() => {
            const codeA = "A".charCodeAt(0);
            const codeZ = "Z".charCodeAt(0);
            const letters = [];
            for (let i = codeA; i <= codeZ; i++) {
                letters.push(String.fromCharCode(i));
            }
            return [...letters, ...["Æ", "Ø", "Å"]];
        });

        const getArtistsByLetter = (letter: string) => {
            return props.artists.filter((artist) => artist.charAt(0) === letter) || [];
        };

        return { alphabet, getArtistsByLetter };
    },
});
