import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c0ef5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "page-container py-6" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "artists-list-letter-items is-flex is-flex-wrap-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: "artists-list",
    "aria-label": _ctx.$t('artists.list')
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alphabet, (letter) => {
        return (_openBlock(), _createElementBlock("section", {
          key: letter,
          class: "artists-list-letter",
          "aria-label": _ctx.$t('artists.starting-with-letter', { letter })
        }, [
          (_ctx.getArtistsByLetter(letter).length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("h2", null, _toDisplayString(letter), 1),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getArtistsByLetter(letter), (artist) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: artist,
                      to: { name: 'Search', query: { creatorsNames: artist } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Block, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(artist), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ], 8, _hoisted_1))
}